.ReactTags__tags {
    font-family: LeagueSpartan, sans-serif;
    position: relative;
    font-variation-settings: 'wght' 700;
}

.ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
}

.ReactTags__tagInput.hide {
    display: none;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    font-family: LeagueSpartan, sans-serif;
    padding: 10px;
    margin: 0;
    font-size: 12px;
    border: 1px solid #eee;
    min-width: 150px;
}

.ReactTags__editInput {
    border-radius: 1px;
}

.ReactTags__editTagInput {
    display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
    opacity: 70% !important;
    background: #DD3D4E;
    color: white;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 2px;
}

.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
    position: absolute;
    bottom: 20px;
}

.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: rgba(26, 26, 26, 0.3);
    backdrop-filter: blur(10px);
    width: fit-content;
    padding: 0;
    color: white;
    border-radius: 8px;
}

.ReactTags__suggestions li {
    padding: 8px 16px;
    margin: 0;
    white-space: nowrap;
}

.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-variation-settings: 'wght' 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
}