@font-face {
  font-family: LeagueSpartan;
  src: url('./assets/fonts/LeagueSpartan-VariableFont_wght.ttf');
}

@font-face {
  font-family: Montserrat;
  src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf');
}

#root,
body {
  --navbar-height: 0px;
  background-color: #282c34;
}

.app-container {
  touch-action: none;
  overscroll-behavior-x: none;
}

.app-container :not(input):not([contentEditable='true']):not(.selectable *):not(.select-text) {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.selectable * {
  user-select: text;
  -webkit-touch-callout: default;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
  pointer-events: all;
}

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[contentEditable='true']:empty:not(:focus)::before,
[data-dblclick-editable='true']:empty:not(:focus)::before,
[data-disabled-editable='true']:empty:not(:focus)::before {
  content: attr(data-text);
  pointer-events: none;
}

b {
  font-weight: 600;
  font-variation-settings: 'wght' 600;
}

/** Scroll bar styling **/
/* Firefox */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  --scrollbar-width: 8px;
  scrollbar-width: thin; /* can also be normal, or none, to not render scrollbar */
  scrollbar-color: currentColor transparent; /* foreground background */
}

/* Webkit / Blink */
::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-thumb {
  background-color: currentColor;
  border-radius: 9999px; /* always round */
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  display: none;
}
