
.dialog-body .editable-div {
    background-color: #50616C;
    border: 0;
    border-radius: 4px;
    color: white;
    vertical-align: top;
    font-family: LeagueSpartan, sans-serif;
    font-size: 1em;
    padding: 8px 4px;
    max-width: 100%;
    min-width: 100%;
    min-height: 50px;
    max-height: 300px;
    height: auto;
    box-sizing: border-box;
    overflow: auto;
}

.dialog-body ::placeholder {
    color: #D9D9DA;
    opacity: 1;
}

.dialog-container {
    pointer-events: auto;
}
